.leaflet-control-container .leaflet-left {
  left: unset;
  right: 10px;
}

.leaflet-control-container .leaflet-top {
  top: unset;
  bottom: 25px;
}

.leaflet-touch .leaflet-bar a {
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: #363739;
  color: white;
}

.leaflet-bar a:first-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.leaflet-overlay-pane {
  svg {
    overflow: visible
  }
}

.jsx-marker {
  display: flex;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: rgba(0, 0, 0, 0.4);
}


.custom-tooltip {
  border: 1px solid #484848;
  background: #171717;
  border-radius: 4px;
  padding: 8px;
  color: white;
  opacity: 1 !important;
  text-align: left;
}

.leaflet-container .leaflet-control-attribution {
  background: rgba(32, 32, 32, 0.7);
  color: white;
  font-size: 8px;

}

.leaflet-container .leaflet-control-attribution a {
  color: inherit;
}

.leaflet-map-pane .leaflet-tile-pane {
  filter: grayscale(1) invert(1);
}
